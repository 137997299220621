import Vue from "vue";
import VueRouter from "vue-router";

//Views
import Home from "@/views/Home.vue";
import Games from "@/views/Games.vue";
import About from "@/views/About.vue";
import Legal from "@/views/Legal.vue";
import Paths from "@/router/nav_paths.json";

import i18n from "@/i18n";
import { ToastProgrammatic as Toast } from 'buefy'

Vue.use(VueRouter);

// Gets the component depending on the path p
// If more components are added to the website this needs to be updated, too
function getComponent(path) {
  switch (path) {
    case "home": case "play": case "explore": case "share": return Home;
    case "games": case "brussels": return Games;
    case "about": case "team": case "project": case "contact": return About;
    case "legal": case "information": case "terms": case "privacy": return Legal;
  }

  console.warn("Component unknown", path);
  return Home; // defaults to home
}

// Creates the routes object for the router
function getRoutes() {

  let routes = [];

  //Add routes
  let paths = Paths.paths;

  routes.push({ path: "/", component: Home, props: { isFrom404: false } });

  Object.keys(paths).forEach(function(pathView) {
    paths[pathView].forEach(function(path) {
      if(path !== "") {
        if(path === pathView) {
          // A main route (/games, /about, /legal)
          routes.push({ path: "/" + path, component: getComponent(path) }); // The default route, used by navigation menus
        }
        else {
          // A route with hash (/games#brussels ...)
          let _pathView = pathView === "home" ? "" : pathView;
          routes.push({ path: "/" + _pathView + "#" + path, component: getComponent(path) }); // The default route with hash, used by navigation menus

          //Alias
          routes.push({ path: "/" + _pathView + "#" + path, alias: "/" + path }); // Visits to /presentation* go to /#project etc.
        }
        routes.push({ path: "/" + path + "*", redirect: "/" + path }); // Path suffixes will be cropped (/presentationblabla=>/presentation)
      }
    });
  });

  //Add redirections
  let redirections = Paths.redirections;

  Object.keys(redirections).forEach(function(pathR) {
    routes.push({ path: "/" + pathR, redirect: "/" + redirections[pathR] });
  });

  //Add aliases
  let aliases = Paths.aliases;

  Object.keys(aliases).forEach(function(pathA) {
    routes.push({ path: "/" + aliases[pathA], alias: "/" + pathA });
  });

  //Add fallback routes
  routes.push({ path: "/*", redirect: "/" });
  routes.push({ path: "*", component: Home, props: { isFrom404: true } });


  return routes;
}

const routes = getRoutes();

//Create router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


// router.beforeEach((to, from, next) => {
//   let title = "";

//   if (to.path != "/") {
//     title = to.path + " - CARD TRIP";
//   } else {
//     title = "CARD TRIP";
//   }
//   document.title = title;
//   next();
// });


router.afterEach((to,from)=>{
  let idTo = to.path.replace("/", ""),
    idFrom = from.path.replace("/", "");

  if(idTo) {
    // Waiting for document load to proceed
    let stateCheck = setInterval(()=>{
      if(document.readyState === 'complete') {
        clearInterval(stateCheck);

        // Get the target element to jump to
        let target = document.getElementById(idTo);
        if(target) {
          let top = target.getBoundingClientRect().top; // These are the viewport coordinates
          let scrollHeight = top + window.scrollY; // We add the current scroll offset

          if(from.name !== null ) {
            //View to section
            if(idFrom === "" || idFrom === "games" || idFrom === "about" || idFrom === "legal") {
              scrollHeight -= 160;
            //Section to section
            } else {
              scrollHeight -= 80;
            }
          } else {
            scrollHeight -= 160;
          }
          window.scrollTo({ top:scrollHeight, left: 0, behavior: "smooth" });
        }
        else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // Scroll to top on main pages
        }
      }
    }, 100); // Check every 100ms
  }
  else {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // Scroll to top on the home page
  }

  if(idTo==="success"||idTo==="cancel") {
    setTimeout(toast, 1000, idTo);
  }
})


//Toasts
function toast(name) {
  if(name==="cancel") {
    Toast.open({
      duration: 4000,
      message: i18n.t("toast_cancel"),
      type: 'is-danger',
      position: 'is-top'
    })
  } else if(name==="success") {
    Toast.open({
      duration: 4000,
      message: i18n.t("toast_success"),
      type: 'is-success',
      position: 'is-top'
    })
  }

}

export default router;

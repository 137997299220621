<template>
  <b-notification v-if="isWarning" :closable="false" type="is-warning" has-icon icon="exclamation-triangle" icon-pack="fas" role="alert">
    <h5 class="title is-5">{{ $t("language_warning") }}</h5>
  </b-notification>
</template>
<script>
import i18n from "@/i18n";

export default {
  name: "WarningLanguageNotification",
  computed: {
    isWarning() {
      return i18n.t("language_warning_flag") === "true";
    }
  }
};
</script>
<style scoped>
.icon {
  height: 0.8rem;
}

.title {
  margin: auto;
}
</style>
import { render, staticRenderFns } from "./RedirectionMessage.vue?vue&type=template&id=210383ce&scoped=true&"
import script from "./RedirectionMessage.vue?vue&type=script&lang=js&"
export * from "./RedirectionMessage.vue?vue&type=script&lang=js&"
import style0 from "./RedirectionMessage.vue?vue&type=style&index=0&id=210383ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "210383ce",
  null
  
)

export default component.exports
<template>
    <h3 v-if="isSubtitle" class="subtitle is-3 alignLeft">{{ $t(fieldName) }}
    	<WarningLanguageIcon v-if="isWarning" size="large" />
    </h3>
    <h2 v-else class="title is-2 headerLines">{{ $t(fieldName) }}
    	<WarningLanguageIcon v-if="isWarning" size="large" />
    </h2>
</template>
<script>
import WarningLanguageIcon from "@/components/warning/WarningLanguageIcon.vue";

export default {
  name: "SectionTitle",
  props: {
    fieldName: String,
    isSubtitle: Boolean,
    isWarning: Boolean
  },
  components: {
  	WarningLanguageIcon
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-hidden-mobile is-hidden-desktop",class:{visible:_vm.isVisible, hidden:!_vm.isVisible},attrs:{"id":"tabletNavigationBar"}},[_c('b-dropdown',{attrs:{"aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('b-button',{staticClass:"has-background-grey-dark has-text-white-ter"},[_c('span',[_vm._v(_vm._s(_vm.$t("nav_home")))]),_c('b-icon',{staticClass:"centerIcon",attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}])},[_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("nav_home")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/play"}},[_vm._v(_vm._s(_vm.$t("nav_home_play")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/explore"}},[_vm._v(_vm._s(_vm.$t("nav_home_explore")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/share"}},[_vm._v(_vm._s(_vm.$t("nav_home_share")))])],1)],1),_c('b-dropdown',{attrs:{"aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('b-button',{staticClass:"has-background-grey-dark has-text-white-ter"},[_c('span',[_vm._v(_vm._s(_vm.$t("nav_games")))]),_c('b-icon',{staticClass:"centerIcon",attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}])},[_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/games"}},[_vm._v(_vm._s(_vm.$t("nav_games")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/brussels"}},[_vm._v(_vm._s(_vm.$t("nav_games_brussels")))])],1)],1),_c('b-dropdown',{attrs:{"aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('b-button',{staticClass:"has-background-grey-dark has-text-white-ter"},[_c('span',[_vm._v(_vm._s(_vm.$t("nav_about")))]),_c('b-icon',{staticClass:"centerIcon",attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}])},[_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/about"}},[_vm._v(_vm._s(_vm.$t("nav_about")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/team"}},[_vm._v(_vm._s(_vm.$t("nav_about_team")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/project"}},[_vm._v(_vm._s(_vm.$t("nav_about_project")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v(_vm._s(_vm.$t("nav_about_contact")))])],1)],1),_c('b-dropdown',{attrs:{"aria-role":"menu"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('b-button',{staticClass:"has-background-grey-dark has-text-white-ter"},[_c('span',[_vm._v(_vm._s(_vm.$t("nav_legal")))]),_c('b-icon',{staticClass:"centerIcon",attrs:{"icon":active ? 'menu-up' : 'menu-down'}})],1)}}])},[_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/legal"}},[_vm._v(_vm._s(_vm.$t("nav_legal")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/information"}},[_vm._v(_vm._s(_vm.$t("nav_legal_information")))])],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/terms"}},[_vm._v(_vm._s(_vm.$t("nav_legal_terms"))+" "),_c('WarningLanguageIcon',{attrs:{"size":"small"}})],1)],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('router-link',{attrs:{"to":"/privacy"}},[_vm._v(_vm._s(_vm.$t("nav_legal_privacy"))+" "),_c('WarningLanguageIcon',{attrs:{"size":"small"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="navigation" class="sticky" :class="{stickyBelowMinimizedNavbar:isBelowMinimizedHeader,stickyBelowMaximizedNavbar:!isBelowMinimizedHeader}">
    <b-menu :accordion="false">
      <!-- <h4 class="title is-4">{{ $t('nav_title') }}</h4> -->
      <b-menu-list aria-role="menu">
        <b-menu-item :active="false" expanded>
          <template slot="label">
            <router-link to="/" class="title-view title is-5">{{ $t("nav_home") }}</router-link>
          </template>
          <b-menu-item :label="$t('nav_home_play')" tag="router-link" aria-role="menuitem" to="/play"></b-menu-item>
          <b-menu-item :label="$t('nav_home_explore')" tag="router-link" aria-role="menuitem" to="/explore"></b-menu-item>
          <b-menu-item :label="$t('nav_home_share')" tag="router-link" aria-role="menuitem" to="/share"></b-menu-item>
        </b-menu-item>
        <b-menu-item :active="false" expanded>
          <template slot="label">
            <router-link to="/games" class="title-view title is-5">{{ $t("nav_games") }}</router-link>
          </template>
          <b-menu-item :label="$t('nav_games_brussels')" tag="router-link" aria-role="menuitem" to="/brussels"></b-menu-item>
        </b-menu-item>
        <b-menu-item :active="false" expanded>
          <template slot="label">
            <router-link to="/about" class="title-view title is-5">{{ $t("nav_about") }}</router-link>
          </template>
          <b-menu-item :label="$t('nav_about_team')" tag="router-link" aria-role="menuitem" to="/team"></b-menu-item>
          <b-menu-item :label="$t('nav_about_project')" tag="router-link" aria-role="menuitem" to="/project"></b-menu-item>
          <b-menu-item :label="$t('nav_about_contact')" tag="router-link" aria-role="menuitem" to="/contact"></b-menu-item>
        </b-menu-item>
        <b-menu-item :active="false" expanded>
          <template slot="label">
            <router-link to="/legal" class="title-view title is-5">{{ $t("nav_legal") }}</router-link>
          </template>
          <b-menu-item :label="$t('nav_legal_information')" tag="router-link" aria-role="menuitem" to="/information"></b-menu-item>
          <b-menu-item tag="router-link" aria-role="menuitem" to="/terms">
            <template slot="label">{{ $t('nav_legal_terms') }}
              <WarningLanguageIcon size="small" /></template>
          </b-menu-item>
          <b-menu-item tag="router-link" aria-role="menuitem" to="/privacy">
            <template slot="label">{{ $t('nav_legal_privacy') }}
              <WarningLanguageIcon size="small" /></template>
          </b-menu-item>
        </b-menu-item>
      </b-menu-list>
    </b-menu>
    <br>
    <!-- Buy button -->
    <!--     <b-button rounded type="is-info" size="is-medium"
      icon-pack="fas" icon-left="shopping-cart"
      tag="router-link" to="/brussels">
      <span class="semibold">{{ $t('order_label_order') }} CARD TRIP</span>
    </b-button> -->
  </div>
</template>
<script>
import WarningLanguageIcon from "@/components/warning/WarningLanguageIcon.vue";

export default {
  name: "LeftColumn",
  props: {
    isBelowMinimizedHeader: Boolean
  },
  components: {
    WarningLanguageIcon
  }
};
</script>
<style>
.menu-list {
  white-space: nowrap;
}

.menu-list li ul {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.menu-list a {
  padding: 0.2rem 0.25rem !important;
}

.menu-list a:hover {
  background-color: hsl(0, 0%, 21%) !important;
}

.menu-list a.is-active {
  background-color: inherit !important;
}

/*.button {
  word-wrap: normal;
}*/

.router-link-exact-active:not(.button) {
  background-color: hsl(0, 0%, 21%) !important;
  font-family: "Exo2-SemiBold", sans-serif;
}

.title-view {
  font-family: "Exo2-SemiBold", sans-serif;
  white-space: break-spaces;
}
</style>
<style scoped>
/* Decrease default section padding and offset the column padding to center the menu */
#navigation {
  padding-left: 1.5rem;
  padding-right: 0.75rem;
}
</style>
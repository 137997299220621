<template>
  <div>
    <section id="introduction" class="section">
      <ImageAndText filePath="photos/product anatomy/ct_bru-cards.png" :text="$t('home_intro')" :isImgRight="true" :isPopout="true" />
    </section>
    <section id="play" class="section">
      <SectionTitle fieldName="home_play_title"></SectionTitle>
      <SectionText fieldName="home_play_introduction"></SectionText>
      <SectionTitle fieldName="home_play_downloadTitle" isSubtitle></SectionTitle>
      <DownloadRules gameRulesID="ctog1" />
      <Tabs isUnique :title="$t('home_play_examplesTitle')" :tabArray="$t('home_play_rules')" />
      <Tabs :title="$t('home_play_variantTitle')" :tabArray="$t('home_play_variants')" />
    </section>
    <section id="explore" class="section">
      <SectionTitle fieldName="home_explore_title"></SectionTitle>
      <SectionText fieldName="home_explore_introduction"></SectionText>
      <ImageAndText filePath="photos/compositions/ct_bru-composition2.png" :title="$t('home_explore_cardSystem_subtitle')" :text="$t('home_explore_cardSystem_content')" :isImgRight="true" :isPopout="true" />
      <ImageAndText filePath="game/ct_bru-plan.png" :title="$t('home_explore_planning_subtitle')" :text="$t('home_explore_planning_content')" :isImgRight="true" :isPopout="true" />
      <Tabs :title="$t('home_explore_alternativeTitle')" :tabArray="$t('home_explore_alternatives')" />
    </section>
    <section id="share" class="section">
      <SectionTitle fieldName="home_share_title"></SectionTitle>
      <SectionText fieldName="home_share_introduction"></SectionText>
      <SectionText fieldName="home_share_experience"></SectionText>
      <Tabs :title="$t('home_share_challengeTitle')" :tabArray="$t('home_share_challenges')" />
    </section>
    <br />
  </div>
</template>
<script>
import DownloadRules from "@/components/DownloadRules.vue";
import Tabs from "@/components/Tabs.vue";
import ImageAndText from "@/components/ImageAndText.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import SectionText from "@/components/SectionText.vue";

export default {
  name: "Home",
  components: {
    DownloadRules,
    Tabs,
    ImageAndText,
    SectionTitle,
    SectionText
  },
  props: {
    isFrom404: { type: Boolean, default: false }
  },
  mounted: function() {
    if (this.isFrom404) {
      this.$emit("redirectedFrom404");
    }
  }
};
</script>
import languages from "@/locales/language_names.json";

function getAllLocales() {
  let languageKeys = Object.keys(languages); // keys are DE, EN, FR, ...

  // Compose the list elements
  let result = [];
  for (let i = 0; i < languageKeys.length; i++) {
    result.push({ key: languageKeys[i], name: languages[languageKeys[i]] });
  }

  return result; // Array of { key, name } where key is EN etc, and name is English etc
}

function getInactiveLocales(activeLocale) {
  let allLocales = getAllLocales();
  let inactiveLocales = allLocales.filter(function(l) {
    return l.key !== activeLocale;
  });

  return inactiveLocales;
}

export { getAllLocales, getInactiveLocales };
<template>
  <div>
    <!-- Desktop -->
    <div class="columns has-padding-left-right is-hidden-touch">
      <br>
      <div class="column image marginAutoVertical">
        <img :src="imgSrc" />
      </div>
      <div class="column hasPaddingLeft">
        <!-- Presentation -->
        <div v-for="section of presentation" :key="section">
          <div v-if="isHTML(section)" v-html="getHTML(section)" />
          <p v-else>{{ section }}</p>
        </div>
        <br>
        <!-- Characteristics -->
        <div class="buttons">
          <button class="button is-green1 is-rounded">
            <b-icon pack="fas" icon="child"></b-icon>
            <span class="semibold">{{ characteristics[0] }}</span>
          </button>
          <button class="button is-green2 is-rounded">
            <b-icon pack="fas" icon="user"></b-icon>
            <span class="semibold">{{ characteristics[1] }}</span>
          </button>
          <button class="button is-green3 is-rounded">
            <b-icon pack="fas" icon="clock"></b-icon>
            <span class="semibold">{{ characteristics[2] }}</span>
          </button>
        </div>
        <!-- References -->
        <ul>
          <li v-for="x of references" :key="x">
            <div v-if="isHTML(x)" v-html="getHTML(x)" />
            <p v-else>{{ x }}</p>
          </li>
        </ul>
        <br>
        <!-- Buy button -->
        <div class="alignLeft buyButton">
          <b-button rounded type="is-info" size="is-medium" icon-pack="fas" icon-left="shopping-cart" @click="isShowModal=true">
            <span class="semibold">{{ $t('order_label_order_pre') }} {{ productName }} {{ $t('order_label_order_post') }}</span>
          </b-button>
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div class="is-hidden-desktop">
      <div class="image image-touch"><img :src="imgSrc" /></div>
      <br>
      <div>
        <!-- Presentation -->
        <div v-for="section of presentation" :key="section">
          <div v-if="isHTML(section)" v-html="getHTML(section)" />
          <p v-else>{{ section }}</p>
        </div>
        <br>
        <!-- Characteristics -->
        <div class="buttons">
          <button class="button is-green1 is-rounded">
            <b-icon pack="fas" icon="child"></b-icon>
            <span class="semibold">{{ characteristics[0] }}</span>
          </button>
          <button class="button is-green2 is-rounded">
            <b-icon pack="fas" icon="user"></b-icon>
            <span class="semibold">{{ characteristics[1] }}</span>
          </button>
          <button class="button is-green3 is-rounded">
            <b-icon pack="fas" icon="clock"></b-icon>
            <span class="semibold">{{ characteristics[2] }}</span>
          </button>
        </div>
        <!-- References -->
        <ul>
          <li v-for="x of references" :key="x">
            <div v-if="isHTML(x)" v-html="getHTML(x)" />
            <p v-else>{{ x }}</p>
          </li>
        </ul>
        <br>
        <!-- Buy button -->
        <div class="buyButton">
          <b-button rounded type="is-info" size="is-medium" icon-pack="fas" icon-left="shopping-cart" @click="isShowModal=true">
            {{ $t('order_label_order_pre') }} {{ productName }} {{ $t('order_label_order_post') }}
          </b-button>
        </div>
      </div>
    </div>
    <!-- Order Modal -->
    <b-modal class="alignLeft" has-modal-card :can-cancel="false" :active.sync="isShowModal">
      <form>
        <div class="modal-card modalCard">
          <!-- CARD HEADER -->
          <header class="modal-card-head">
            <span class="bold modal-card-title">{{ $t('order_label_orderTitle') }}</span>
          </header>
          <!-- CARD BODY -->
          <section class="modal-card-body">
            <!-- Quantity selection -->
            <div class="box validBox">
              <div class="media">
                <figure class="media-left">
                  <p class="image is-48x48">
                    <img :src="imgSrc" />
                  </p>
                </figure>
                <div class="media-content">
                  <b-field grouped class="spaceBetween">
                    <!-- Tablet+ -->
                    <b-field custom-class="title is-4" :label="$t('order_label_product')" class="is-hidden-mobile">
                      <span class="subtitle is-5">{{ productName }}</span>
                    </b-field>
                    <!-- Mobile -->
                    <b-field custom-class="title is-4" :label="$t('order_label_product')" class="is-hidden-tablet">
                      <span class="subtitle is-5">{{ simpleName(productName) }}</span>
                    </b-field>
                    <b-field custom-class="title is-4" :label="$t('order_label_quantity')">
                      <b-numberinput controls-rounded :editable="false" size="is-small" type="is-info" min="1" max="5" controls-position="compact" v-model="selectedQuantity"></b-numberinput>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>
            <!-- Country selection -->
            <!-- Tablet+ -->
            <div class="box is-hidden-mobile" :class="(selectedCountry?'validBox':'infoBox')">
              <div class="media">
                <div class="media-left marginAutoVertical">
                  <span class="title is-4">{{ $t('order_label_country') }}</span>
                </div>
                <div class="media-content">
                  <b-field :type="(selectedCountry?'is-success':'')">
                    <b-autocomplete rounded open-on-focus keep-first clearable icon="search" icon-pack="fas" v-model="inputCountry" field="name" ref="autocomplete" :data="filteredCountries" :placeholder="$t('order_field_countryPlaceholder')" @select="x => selectedCountry = x">
                      <template slot-scope="props"><span class="subtitle is-5" v-html="props.option.htmlName||props.option.name" /></template>
                      <template slot="empty"><span class="subtitle is-5">{{ $t('order_field_countryNoResult') }}</span></template>
                    </b-autocomplete>
                  </b-field>
                </div>
              </div>
            </div>
            <!-- Mobile -->
            <div class="box is-hidden-tablet" :class="(selectedCountry?'validBox':'infoBox')">
              <b-field custom-class="title is-4" :label="$t('order_label_country')" :type="(selectedCountry?'is-success':'')" />
              <b-autocomplete rounded open-on-focus keep-first clearable icon="search" icon-pack="fas" v-model="inputCountry" field="name" ref="autocomplete" :data="filteredCountries" :placeholder="$t('order_field_countryPlaceholder')" @select="x => selectedCountry = x">
                <template slot-scope="props"><span class="subtitle is-5" v-html="props.option.htmlName||props.option.name" /></template>
                <template slot="empty"><span class="subtitle is-5">{{ $t('order_field_countryNoResult') }}</span></template>
              </b-autocomplete>
            </div>
            <!-- Prices & Promos -->
            <transition name="fade">
              <div class="box" v-if="selectedCountryOnce" :class="{'validBox':selectedCountry}">
                <!-- Prices -->
                <b-field custom-class="title is-4 headerLinesRight" :label="$t('order_label_price')">
                  <div class="control">
                    <b-field grouped group-multiline>
                      <span class="control">{{ productName }} x{{ selectedQuantity }}</span>
                      <div class="control alignElementRight">
                        <span v-show="isPromoProductValid" class="oldPrice">{{ displayDecimals(priceProductGross) }} €</span>
                        <span v-if="isPromoProductValid" class="newPrice"> {{ displayDecimals(priceProductPromo) }} €</span>
                        <span v-else> {{ displayDecimals(priceProductGross) }} €</span>
                      </div>
                    </b-field>
                    <b-field grouped group-multiline v-if="selectedCountry">
                      <span class="control">{{ $t('order_label_deliveryFees') }} - {{selectedCountry.name}}</span>
                      <div class="control alignElementRight">
                        <span v-show="isPromoShipmentValid" class="oldPrice">{{ displayDecimals(priceShippingGross) }} €</span>
                        <span v-if="isPromoShipmentValid" class="newPrice"> {{ displayDecimals(priceShippingPromo) }} €</span>
                        <span v-else> {{ displayDecimals(priceShippingGross) }} €</span>
                      </div>
                    </b-field>
                    <b-field grouped group-multiline v-if="selectedCountry">
                      <span class="control title is-5">{{ $t('order_label_total') }}</span>
                      <div class="control title is-5 alignElementRight">
                        <span v-show="isPromoProductValid||isPromoShipmentValid" class="oldPrice">{{ displayDecimals(priceTotalGross) }} €</span>
                        <span v-if="isPromoProductValid&&isPromoShipmentValid" class="newPrice"> {{ displayDecimals(priceTotalPromos) }} €</span>
                        <span v-else-if="isPromoProductValid" class="newPrice"> {{ displayDecimals(priceTotalProductPromo) }} €</span>
                        <span v-else> {{ displayDecimals(priceTotalGross) }} €</span>
                      </div>
                    </b-field>
                  </div>
                </b-field>
                <!-- Promos -->
                <b-field class="cardChapter">
                  <b-field grouped group-multiline>
                    <!-- Promo product  -->
                    <div class="control">
                      <b-taglist :attached="isPromoProductNotValid">
                        <b-tag v-if="isPromoProductValid" type="is-success" size="is-medium">✔ {{ $t('order_promo_productCost_offer') }}</b-tag>
                        <b-tag v-if="isPromoProductNotValid" type="is-danger" size="is-medium">{{ $t('order_promo_productCost_offer') }}</b-tag>
                        <b-tag v-if="isPromoProductNotValid" type="is-dark" size="is-medium">{{ $t('order_promo_productCost_condition') }}</b-tag>
                      </b-taglist>
                    </div>
                    <!-- Promo shipping -->
                    <div class="control">
                      <b-taglist :attached="isPromoShipmentNotValid">
                        <b-tag v-if="isPromoShipmentValid" type="is-success" size="is-medium">✔ {{ $t('order_promo_shippingCost_offer') }}</b-tag>
                        <b-tag v-if="isPromoShipmentNotValid" type="is-danger" size="is-medium">{{ $t('order_promo_shippingCost_offer') }}</b-tag>
                        <b-tag v-if="isPromoShipmentNotValid" type="is-dark" size="is-medium">{{ $t('order_promo_shippingCost_condition') }}</b-tag>
                      </b-taglist>
                    </div>
                  </b-field>
                </b-field>
              </div>
            </transition>
            <!-- Payment Method -->
            <transition name="fade">
              <div class="box" v-if="selectedCountryOnce" :class="(selectedCountry?(selectedMethod?'validBox':'infoBox'):'')">
                <!-- Tablet+ -->
                <div class="is-hidden-mobile">
                  <b-field custom-class="title is-4" :label="$t('order_label_method')">
                    <div class="control">
                      <b-radio v-for="method in filteredMethods" :key="method" :value="method" v-model="selectedMethod" type="is-info" size="is-medium" :native-value="method" :disabled="selectedCountry===null">
                        {{ method }}
                      </b-radio>
                    </div>
                  </b-field>
                </div>
                <!-- Mobile -->
                <div class="is-hidden-tablet">
                  <b-field custom-class="title is-4" :label="$t('order_label_method')" />
                  <b-field v-for="method in filteredMethods" :key="method" :value="method">
                    <b-radio v-model="selectedMethod" type="is-info" size="is-medium" :native-value="method" :disabled="selectedCountry===null">
                      {{ method }}
                    </b-radio>
                  </b-field>
                </div>
                <!-- Common -->
                <span class="subtitle is-6 italic has-text-grey-light" v-if="isPaypalDisabled">{{ $t('order_label_paypalUnavailable') }}</span>
              </div>
            </transition>
            <!-- ToS Checkbox -->
            <transition name="fade">
              <b-field grouped class="cardChapterEnd" v-if="selectedCountryOnce">
                <b-checkbox type="is-info" size="is-medium" v-model="isTermsAgreed">
                  {{ $t('order_field_terms_pre') }} <router-link target="_blank" to="/terms" class="bold">{{ $t('order_field_terms_mid') }}</router-link> {{ $t('order_field_terms_post') }}
                </b-checkbox>
                <div class="control">
                </div>
              </b-field>
            </transition>
          </section>
          <!-- CARD FOOTER -->
          <!-- Tablet+ -->
          <footer class="modal-card-foot is-hidden-mobile">
            <!-- Paypal button -->
            <b-button v-if="isPaypal" :disabled="!isFormValid" size="is-medium" type="is-info" icon-left="lock" icon-pack="fas" tag="a" :href="paypalHref" target="_blank">
              {{ $t('order_label_orderPaypal') }}
            </b-button>
            <!-- Mail button -->
            <b-button v-else-if="selectedMethod" :disabled="!isFormValid" size="is-medium" type="is-info" icon-left="paper-plane" icon-pack="fas" @click="sendMail">
              {{ $t('order_label_orderMail') }}
            </b-button>
            <!-- Cancel button -->
            <b-button class="alignElementRight" size="is-medium" type="is-primary" @click="resetOrder">
              {{ $t('order_label_cancel') }}
            </b-button>
          </footer>
          <!-- Mobile -->
          <footer class="modal-card-foot is-hidden-tablet buttons spaceBetween">
            <!-- Paypal button -->
            <b-button v-if="isPaypal" :disabled="!isFormValid" size="is-medium" type="is-info" icon-left="lock" icon-pack="fas" tag="a" :href="paypalHref" target="_blank">
              {{ $t('order_label_orderPaypal') }}
            </b-button>
            <!-- Mail button -->
            <b-button v-else-if="selectedMethod" :disabled="!isFormValid" size="is-medium" type="is-info" icon-left="paper-plane" icon-pack="fas" @click="sendMail">
              {{ $t('order_label_orderMail') }}
            </b-button>
            <!-- Cancel button -->
            <b-button size="is-medium" type="is-primary" @click="resetOrder">
              {{ $t('order_label_cancel') }}
            </b-button>
          </footer>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { isHTML, getHTML } from "@/helpers/parser.js";
import { displayDecimals, simpleName, formatMailAddress, formatOrderMailSubject, formatOrderMailContent, mergeArrays } from "@/helpers/dataHelper.js";

import i18n from "@/i18n";
import products from "@/assets/data/products.json";

const data=function() {
   return {
    isShowModal: false,
    selectedQuantity: 1,
    selectedCountry: null,
    selectedCountryOnce: false,
    inputCountry: "",
    selectedMethod: null,
    isTermsAgreed: false
  }
}

export default {
  name: "GameDescription",
  data: () => ({
    ...data()
  }),
  props: {
    productID: String,
    photoFilePath: String,
    presentation: Array,
    references: Array,
    characteristics: Array
  },
  computed: {
    primary(){
      return products[this.productID].order_country_priorities_primary;
    },
    secondaryList(){
      return products[this.productID].order_country_priorities_secondary_list;
    },
    productName(){
      return products[this.productID].product_name;
    },
    methods() {
      return i18n.t('order_field_methods');
    },
    countries() {
      return mergeArrays([i18n.t('order_field_countries'), products.order_countries], "id");
    },
    imgSrc: function() {
      return require('@/assets/img/' + this.photoFilePath);
    },
    filteredMethods() {
      let methods = [...this.methods];
      if (this.isPaypalDisabled) {
        methods.shift();
      }
      return methods;
    },
    priceProductGross() {
      return this.selectedQuantity && products[this.productID].order_prices_product[this.selectedQuantity - 1];
    },
    priceShippingGross() {
      return this.selectedQuantity && this.selectedCountry && products[this.productID].order_prices_shipping[this.selectedCountry.zone][this.selectedQuantity - 1];
    },
    priceTotalGross() {
      return this.priceProductGross + this.priceShippingGross;
    },
    priceProductPromo() {
      return this.selectedQuantity && products[this.productID].order_prices_product_promo[this.selectedQuantity - 1];
    },
    priceShippingPromo() {
      return this.selectedQuantity && this.selectedCountry && products[this.productID].order_prices_shipping_promo[this.selectedCountry.zone][this.selectedQuantity - 1];
    },
    priceTotalPromos() {
      return this.priceProductPromo + this.priceShippingPromo;
    },
    priceTotalProductPromo() {
      return this.priceProductPromo + this.priceShippingGross;
    },
    priceTotalFinal() {
      if (this.isPromoProductValid && this.isPromoShipmentValid) {
        return this.priceTotalPromos;
      } else if (this.isPromoProductValid) {
        return this.priceTotalProductPromo;
      } else {
        return this.priceTotalGross;
      }
    },
    isPaypalDisabled() {
      return this.selectedCountry && !this.selectedCountry.paypal;
    },
    isPaypal() {
      return this.selectedMethod === i18n.t('order_field_methods[0]');
    },
    isBankTransfer() {
      return this.selectedMethod === i18n.t('order_field_methods[1]');
    },
    isPromoProductNotValid() {
      return this.selectedQuantity === 1;
    },
    isPromoProductValid() {
      return this.selectedQuantity > 1;
    },
    isPromoShipmentNotValid() {
      return this.selectedCountry && this.selectedCountry.zone === 0 && this.selectedQuantity < 5;
    },
    isPromoShipmentValid() {
      return this.selectedCountry && this.selectedCountry.zone === 0 && this.selectedQuantity === 5;
    },
    isFormValid() {
      return this.isTermsAgreed && this.selectedCountry && this.selectedQuantity;;
    },
    filteredCountries() {
      return this.customFilter(this.sortedCountries).filter((country) => {
        if (country.hidden === undefined && country.zone !== -1) {
          return true;
        } else {
          return false;
        }
      });
    },
    paypalHref() {
      if (this.isFormValid) {
        return products[this.productID].order_paypal_pre_link + products[this.productID].order_paypal_post_links[this.selectedCountry.zone][this.selectedQuantity - 1]
      } else {
        return ""
      }
    },
    sortedCountries() {
      let array = [...this.countries];
      return array.sort((a, b) => {
        //Put primary on top
        if (this.optionType(a) === 2) {
          return -1;
        } else if (this.optionType(b) === 2) {
          return 1;
          //Sort secondaries by name
        } else if (this.optionType(a) === 1 && this.optionType(b) === 1) {
          return new Intl.Collator().compare(a.name, b.name);
          //Put secondary before the rest
        } else if (this.optionType(a) === 1 && this.optionType(b) === 0) {
          return -1;
          //Sort the rest by name
        } else if (this.optionType(a) === 0 && this.optionType(b) === 1) {
          return 1;
          //Sort the rest by name
        } else {
          return new Intl.Collator().compare(a.name, b.name);
        }
      });
    }
  },
  methods: {
    isHTML,
    getHTML,
    displayDecimals,
    simpleName,
    customFilter(countries) {
      let inputCountry = this.inputCountry.normalize("NFD");

      if (inputCountry.length > 0 && inputCountry.length < 50) {
        let query = "^";

        //Insert wildcards, capturing groups and escape regex
        for (let i = 0; i < inputCountry.length; i++) {
          query += "(.*?)(" + inputCountry.charAt(i).replace(/[.*+?^$()|[\]\\]/gu, '\\$&') + ")"
        }

        query += "(.*)$"

        // &
        const results = [];

        countries.forEach(function(country) {
          const countryName = country.name.normalize("NFD");
          //Case insensitive Regex, find match
          const matchResult = new RegExp(query, 'gui').exec(countryName);

          //score by entry
          if (matchResult) {
            let
              scoreGlobalBefore = 0,
              scoreGlobalAfter = 0,
              scoreBefore = 0,
              scoreAfter = 0,
              scoreGlobalIn = 0,
              scoreIn = 0,
              index = 0,
              highlightedIndexes = [];

            for (let j = 1; j < matchResult.length; j++) {
              if (j === matchResult.length - 1) {
                //Last Match
                const split = matchResult[j].split(/[,:;.-\s]+/);
                const first = split.shift();

                scoreAfter += first.length
                scoreGlobalAfter = matchResult[j].length - first.length - split.length;
              } else if (j === 1) {
                //First Match
                const split = matchResult[j].split(/[,:;.-\s]+/);
                const last = split.pop();

                scoreBefore += last.length
                scoreGlobalBefore = matchResult[j].length - last.length - split.length;
              } else if (j % 2 !== 0) {
                //Between Char String
                const split = matchResult[j].split(/[,:;.-\s]+/);
                if (split.length >= 2) {
                  const first = split.shift();
                  const last = split.pop();

                  scoreAfter += first.length
                  scoreBefore += last.length

                  if (split.length > 0) {
                    split.forEach(function(s) {
                      scoreGlobalIn += s.length;
                    });
                  }
                } else {
                  scoreIn += matchResult[j].length;
                }
              } else {
                //Character String
                highlightedIndexes.push(index);
              }
              index += matchResult[j].length;
            }

            let rate = Math.max(10 - scoreIn - scoreBefore, 3) * 10 -
              Math.min(Math.max(scoreAfter, 0), 20) -
              Math.min(Math.max(scoreGlobalBefore + scoreGlobalIn + scoreGlobalAfter, 0), 9);

            let htmlName = "";

            for (let k = 0; k < countryName.length; k++) {

              if (highlightedIndexes.includes(k)) {
                htmlName += '<span class="highlightedChar">' + countryName.charAt(k) + '</span>';
              } else {
                htmlName += countryName.charAt(k);
              }
            }

            results.push({ ...country, rate, htmlName });
          }
        });

        //Sort entries by match score
        return results.sort((a, b) => b.rate - a.rate);
      }

      return countries;
    },
    optionType(option) {
      if (option.id === this.primary) {
        return 2;
      } else if (this.secondaryList.includes(option.id)) {
        return 1;
      } else {
        return 0;
      }
    },
    resetOrder() {
      Object.assign(this.$data, data())
    },
    sendMail() {
      if (this.isFormValid) {
        let addr = formatMailAddress("order");
        let subj = formatOrderMailSubject(this.productName, this.selectedQuantity, this.selectedCountry);
        let msg = formatOrderMailContent(this.productName, this.selectedCountry, this.priceTotalFinal);

        window.location.href = "mailto:" + addr + "?subject=" + subj + "&body=" + encodeURIComponent(msg);
      }
    }
  },
  watch: {
    selectedCountry: function(newValue) {
      this.selectedMethod = null;
      if (newValue !== null) {
        this.selectedCountryOnce = true;
      }
    }
  }
};
</script>
<style scoped>
.box {
  border-left: .3em solid transparent;
}

.infoBox {
  border-left: .3em solid #f9e300;
}

.validBox {
  border-left: .3em solid #57b35f;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modalCard {
  min-height: 600px;
}

/*to change*/
@media screen and (min-width: 769px) {
  .modalCard {
    width: 600px;
  }
}

.cardChapter {
  padding-top: 1rem;
}

.cardChapterEnd {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

.oldPrice {
  text-decoration: line-through;
}

.newPrice {
  color: #57b35f;
}

.alignElementRight {
  margin-left: auto;
}

.buyButton {
  margin-top: 1rem;
}

.image {
  max-width: 400px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.marginAutoVertical {
  margin-top: auto;
  margin-bottom: auto;
}

.image-touch {
  margin-bottom: 24px;
}

.has-padding-left-right {
  padding-left: 10px;
  padding-right: 10px;
}

.hasPaddingLeft {
  padding: 0 0 0 40px;
}

.columns {
  margin-top: 0;
}
</style>
<template>
  <div id="redirectionMessage" class="has-background-primary has-text-dark">{{ $t("general_notFoundError") }}</div>
</template>
<script>
export default {
  name: "App"
};
</script>
<style scoped>
#redirectionMessage {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
<template>
  <section class="tab-section">
    <b-collapse :open.sync="isOpen" class="card" animation="slide">
      <div slot="trigger" slot-scope="props" class="card-header" role="button">
        <h5 v-if="isSmall" class="subtitle is-5 card-header-title alignLeft">{{ title }}</h5>
        <h3 v-else class="subtitle is-3 card-header-title alignLeft">{{ title }}</h3>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-left'" size="is-large"></b-icon>
        </a>
      </div>
      <b-tabs v-if="!isUnique" type="is-boxed" size="is-medium">
        <b-tab-item class="block has-round-borders" v-for="item in tabArray" :key="item.header">
          <template slot="header">
            <span class="has-normal-whitespace-wrap">{{ item.header }}</span>
          </template>
          <div class="column">
            <ul v-if="tabArray">
              <li v-for="x of item.content" :key="x">
                <div v-if="isHTML(x)" v-html="getHTML(x)" />
                <img v-else-if="isPicture(x)" :src="getPicture(x)" class="tab-image" />
                <p v-else>{{ x }}</p>
              </li>
            </ul>
          </div>
        </b-tab-item>
      </b-tabs>
      <div v-if="isUnique" class="column">
        <ul v-if="tabArray">
          <li v-for="x of tabArray" :key="x">
            <div v-if="isHTML(x)" v-html="getHTML(x)" />
            <img v-else-if="isPicture(x)" :src="getPicture(x)" class="tab-image" />
            <p v-else>{{ x }}</p>
          </li>
        </ul>
      </div>
    </b-collapse>
  </section>
</template>
<script>
import { isHTML, getHTML, isPicture, getPicture } from "@/helpers/parser.js";

export default {
  name: "Tabs",
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    title: String,
    isSmall: Boolean,
    isUnique: Boolean,
    tabArray: Array
  },
  methods: {
    isHTML,
    getHTML,
    isPicture,
    getPicture
  }
};
</script>
<style>
.tab-image {
  max-width: 500px;
  /*height: 100%;*/
  max-height: 250px;
  display: block;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tab-content {
  padding: 0 !important;
  background-color: #464646;
  border: 3px solid #464646;
  border-radius: 0 0 8px 8px;
}

.tabs.is-boxed a {
  border-top: 1px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 0;
  border-right: 0;
  border-radius: 8px 8px 0 0;
}
</style>
<style scoped>
.block {
  margin-bottom: 0;
}

.card {
  border: 3px solid #464646;
  border-radius: 8px;
  box-shadow: none;
}

.card-header {
  background-color: #464646;
}

.has-round-borders {
  border-radius: 10px;
}

.has-normal-whitespace-wrap {
  white-space: normal;
}

.subtitle {
  margin: 0;
}

.tab-section {
  padding-top: 36px;
  /*max-width: 600px;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
}
</style>
<template>
  <div id="external" class="sticky" :class="{stickyBelowMinimizedNavbar:isBelowMinimizedHeader,stickyBelowMaximizedNavbar:!isBelowMinimizedHeader}">
    <div id="references">
      <h4 class="title is-4 is-small">{{ $tc('rightColumn_title_publisher',links.publishers.length) }}</h4>
      <div class="columns is-multiline is-centered is-variable is-1">
        <div class="column is-narrow" v-for="publisher in links.publishers" :key="publisher.img">
          <a :href="publisher.link" target="_blank">
            <img class="publisherIcon" alt="key" :src="require('@/assets/img/labels/' + publisher.img)" />
          </a>
        </div>
      </div>
      <h4 class="title is-4 is-small">{{ $tc('rightColumn_title_manufacturer',links.manufacturers.length) }}</h4>
      <div class="columns is-multiline is-centered is-variable is-1">
        <div class="column is-narrow" v-for="manufacturer in links.manufacturers" :key="manufacturer.img">
          <a :href="manufacturer.link" target="_blank">
            <img class="manufacturerIcon" alt="key" :src="require('@/assets/img/labels/' + manufacturer.img)" />
          </a>
        </div>
      </div>
      <h4 class="title is-4 is-small">{{ $tc('rightColumn_title_translator',links.translators.length) }}</h4>
      <div class="columns is-multiline is-centered is-variable is-1">
        <div class="column is-narrow" v-for="translator in links.translators" :key="translator.name">
          <a v-if="translator.link" class="textLink" :href="translator.link" target="_blank">{{ translator.name }}</a>
          <span v-else>{{ translator.name }}</span>
        </div>
      </div>
      <hr />
      <div class="columns is-multiline is-centered is-variable is-1">
        <div class="column is-narrow">
          <a :href="links.facebook.link" target="_blank">
            <img class="socialIcon" :src="require('@/assets/img/labels/' + links.facebook.img)" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import links from "@/assets/data/links.json";

export default {
  name: "RightColumn",
  props: {
    isBelowMinimizedHeader: Boolean
  },
  data: function() {
    return { links };
  }
};
</script>
<style scoped>
/* Remove the focus outline from social media icons */
a:focus {
  outline: none;
}

#external {
  padding-right: 0.75rem;
  right: 0;
}

#references {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

h4,
h5 {
  margin-top: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.publisherIcon {
  max-height: 96px;
  max-width: 96px;
}

.manufacturerIcon {
  max-height: 96px;
  max-width: 96px;
}

.translatorIcon {
  max-height: 96px;
  max-width: 144px;
}

.socialIcon {
  max-height: 96px;
  max-width: 128px;
}

.column {
  padding-top: 0;
  padding-bottom: 0;
}

.columns {
  margin-top: 0;
}

.small {
  font-size: 11px;
}

hr {
  background-color: #ffffff;
  height: 2px;
  margin: 1.5rem;
}
</style>
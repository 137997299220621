<template>
  <div>
    <div v-for="section of $t(fieldName)" :key="section">
      <div v-if="isHTML(section)" v-html="getHTML(section)" />
      <p v-else>{{ section }}</p>
    </div>
  </div>
</template>
<script>
import { isHTML, getHTML } from "@/helpers/parser.js";

export default {
  name: "SectionText",
  props: {
    fieldName: String
  },
  methods: {
    isHTML,
    getHTML
  }
};
</script>
<template>
  <div id="burger">
    <b-navbar-item class="title-view title is-5" tag="router-link" to="/" @click="closeMenu">{{ $t("nav_home") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/play" @click="closeMenu">{{ $t("nav_home_play") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/explore" @click="closeMenu">{{ $t("nav_home_explore") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/share" @click="closeMenu">{{ $t("nav_home_share") }}</b-navbar-item>
    <b-navbar-item class="title-view title is-5" tag="router-link" to="/games" @click="closeMenu">{{ $t("nav_games") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/brussels" @click="closeMenu">{{ $t("nav_games_brussels") }}</b-navbar-item>
    <b-navbar-item class="title-view title is-5" tag="router-link" to="/about" @click="closeMenu">{{ $t("nav_about") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/team" @click="closeMenu">{{ $t("nav_about_team") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/project" @click="closeMenu">{{ $t("nav_about_project") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/contact" @click="closeMenu">{{ $t("nav_about_contact") }}</b-navbar-item>
    <b-navbar-item class="title-view title is-5" tag="router-link" to="/legal" @click="closeMenu">{{ $t("nav_legal") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/information" @click="closeMenu">{{ $t("nav_legal_information") }}</b-navbar-item>
    <b-navbar-item tag="router-link" to="/terms" @click="closeMenu">{{ $t("nav_legal_terms") }}
      <WarningLanguageIcon size="small" />
    </b-navbar-item>
    <b-navbar-item tag="router-link" to="/privacy" @click="closeMenu">{{ $t("nav_legal_privacy") }}
      <WarningLanguageIcon size="small" />
    </b-navbar-item>
    <div :hidden="!isMobile">
      <hr />
      <h5 class="title is-5">{{ $tc('rightColumn_title_publisher',links.publishers.length) }}</h5>
      <span v-for="publisher in links.publishers" :key="publisher.img">
        <a :href="publisher.link" target="_blank" @click="closeMenu">
          <img class="publisherIcon" alt="key" :src="require('@/assets/img/labels/' + publisher.img)" />
        </a>
      </span>
      <h5 class="title is-5">{{ $tc('rightColumn_title_manufacturer',links.manufacturers.length) }}</h5>
      <span v-for="manufacturer in links.manufacturers" :key="manufacturer.img">
        <a :href="manufacturer.link" target="_blank" @click="closeMenu">
          <img class="manufacturerIcon" alt="key" :src="require('@/assets/img/labels/' + manufacturer.img)" />
        </a>
      </span>
      <h5 class="title is-5">{{ $tc('rightColumn_title_translator',links.translators.length) }}</h5>
      <div v-for="translator in links.translators" :key="translator.name">
        <a v-if="translator.link" class="textLink" :href="translator.link" target="_blank">{{ translator.name }}</a>
        <span v-else>{{ translator.name }}</span>
      </div>
      <hr />
      <span>
        <a :href="links.facebook.link" target="_blank">
          <img class="socialIcon" :src="require('@/assets/img/labels/' + links.facebook.img)" />
        </a>
      </span>
    </div>
  </div>
</template>
<script>
import links from "@/assets/data/links.json";
import WarningLanguageIcon from "@/components/warning/WarningLanguageIcon.vue";

export default {
  name: "HeaderBurgerMenu",
  data: function() {
    return { links };
  },
  props: {
    isMobile: Boolean
  },
  components: {
    WarningLanguageIcon
  },
  methods: {
    closeMenu: function() {
      let menu = document.getElementsByClassName("navbar-menu")[0];
      let burger = document.getElementsByClassName("navbar-burger")[0];

      if (menu && burger) {
        menu.classList.toggle("is-active");
        burger.classList.toggle("is-active");
      }
    }
  }
};
</script>
<style scoped>
a:focus {
  outline: none;
}

h5 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem !important;
}

.title-view {
  font-family: "Exo2-SemiBold", sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.router-link-exact-active {
  background-color: hsl(0, 0%, 21%) !important;
  font-family: "Exo2-SemiBold", sans-serif;
}

.publisherIcon {
  max-height: 96px;
  max-width: 96px;
}

.manufacturerIcon {
  max-height: 96px;
  max-width: 96px;
}

.translatorIcon {
  max-height: 96px;
  max-width: 144px;
}

.socialIcon {
  max-height: 96px;
  max-width: 96px;
}

#burger {
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
</style>
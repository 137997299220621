import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import Buefy from 'buefy'
//import 'buefy/dist/buefy.css' // not needed here because we are doing sass customization in App.vue
import i18n from "./i18n";

Vue.use(Buefy)

Vue.config.productionTip = false;

// This is a custom directive for using on-scroll on vue components.
// See https://vuejs.org/v2/cookbook/creating-custom-scroll-directives.html
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");


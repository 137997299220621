<template>
  <div id="app" class="has-background-dark has-text-white">
    <Header :isMinimized="isHeaderMinimized" :isMobileSmall="isMobileSmall" :isMobile="isMobile" :isTablet="isTablet" :isDesktop="isDesktop" />
    <div id="body has-text-white">
      <div class="columns">
        <div class="column is-narrow is-hidden-touch has-background-grey-dark">
          <LeftColumn :isBelowMinimizedHeader="isHeaderMinimized" />
        </div>
        <div id="routerView" class="column">
          <router-view v-scroll="onScroll" v-on:redirectedFrom404="showRedirectionError" />
        </div>
        <div class="column is-narrow is-hidden-mobile has-background-grey-dark">
          <RightColumn :isBelowMinimizedHeader="isHeaderMinimized" />
        </div>
      </div>
    </div>
    <div :hidden="!isShowRedirectionError">
      <RedirectionMessage />
    </div>
    <Footer />
  </div>
</template>
<script>
import "./styles/font.scss";
import "./styles/buefy.scss";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import LeftColumn from "@/components/sidebars/LeftColumn.vue";
import RightColumn from "@/components/sidebars/RightColumn.vue";
import RedirectionMessage from "@/components/RedirectionMessage.vue";
import i18n from "@/i18n";

export default {
  name: "App",
  data: function() {
    return {
      isHeaderMinimized: false,
      isMobileSmall: false,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      isShowRedirectionError: false
    };
  },
  components: {
    Footer,
    Header,
    LeftColumn,
    RightColumn,
    RedirectionMessage
  },
  methods: {
    languageSnackbar: function() {
      this.$buefy.snackbar.open({
        duration: 5000,
        message: i18n.t("snackbar_language"),
        type: 'is-white',
        position: 'is-top-right'
      })
    },
    onScroll: function() {
      this.isHeaderMinimized = window.scrollY > 0;
    },
    onResize: function() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.isDesktop = width >= 1024;
      this.isMobile = width < 769;
      this.isMobileSmall = width < 569;
      this.isTablet = !(this.isDesktop || this.isMobile || this.isMobileSmall);
    },
    showRedirectionError: function() {
      this.isShowRedirectionError = true;
      setTimeout(this.hideRedirectionError, 10000);
    },
    hideRedirectionError: function() {
      this.isShowRedirectionError = false;
    }
  },
  mounted: function() {
    window.addEventListener("resize", this.onResize);
    this.onResize();

    this.languageSnackbar();
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>
<style>
#app {
  font-family: "Exo2-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.bold {
  font-family: "Exo2-Bold", sans-serif;
}

.semibold {
  font-family: "Exo2-SemiBold", sans-serif;
}

.regular {
  font-family: "Exo2-Regular", sans-serif !important;
}

.italic {
  font-family: "Exo2-Italic", sans-serif !important;
}

.section {
  padding: 3rem 1.5rem 0 !important;
}

.textLink {
  text-decoration: underline;
}

.b-numberinput div input {
  width: 32px;
}

.checkbox:hover,
.radio:hover {
  /*color: #32b5e9;*/
  color: #ffffff;
}

fill-rule:a {
  color: #31e8e8;
}

body {
  padding-bottom: 3em;
}

button {
  font-family: inherit !important;
}

h3 {
  margin-bottom: 0.6rem;
  margin-top: 1.5rem;
}

h5 {
  margin-bottom: 0.4rem;
  margin-top: 1rem;
}

h1,
h2,
h3,
h4,
h5,
.title,
.subtitle {
  font-family: "Exo2-Bold", sans-serif;
}

p,
ul {
  text-align: left;
}

#routerView {
  padding-left: calc(5%);
  padding-right: calc(5%);
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

/*div>span.icon {
  color: #9F9F9F !important;
}*/

.noMarginVertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #9F9F9F !important;
}

::placeholder {
  color: #9F9F9F !important;
  opacity: 1 !important;
}


.is-narrow {
  width: 240px;
}

/* Centers icons vertically inside a button */
.centerIcon {
  vertical-align: middle;
}

/* Disable scrolling on left and right columns */
.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
}

/*.select select {
  padding-right: 1.75rem !important;
}*/

/* This is attached to the left and right columns, when the navbar is minimized */
.stickyBelowMinimizedNavbar {
  top: 6.5rem;
  transition: top 0.75s;
}

/* This is attached to the left column, when the navbar is maximized */
.stickyBelowMaximizedNavbar {
  top: 11.5rem;
  transition: top 0.75s;
}

/* Show white line to the left and right of headers */
.headerLines {
  overflow: hidden;
  text-align: center;
  padding-bottom: 2px;
}

.headerLines:before,
.headerLines:after {
  background-color: hsl(0, 0%, 96%);
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.headerLines:before {
  right: 0.5em;
  margin-left: -50%;
}

.headerLines:after {
  left: 0.5em;
  margin-right: -50%;
}

/* Show white line to the left and right of headers */
.headerLinesRight {
  overflow: hidden;
  padding-bottom: 2px;
}

.headerLinesRight:after {
  background-color: hsl(0, 0%, 40%);
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 95%;
  left: 0.5em;
  margin-right: -50%;
}

.headerLinesRightModal {
  overflow: hidden;
  padding-bottom: 2px;
}

.headerLinesRightModal:after {
  background-color: hsl(0, 0%, 40%);
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 70%;
  left: 0.5em;
  margin-right: -50%;
}

.section {
  padding: 3rem 1.5rem 0 !important;
}

.remark {
  color: #AAFFFF;
  padding-left: 10px;
  border-left-style: solid;
  border-left-width: 3px;
  color: #AAFFFF;
}

textarea::placeholder {
  opacity: 1;
}

.tabs ul {
  border-bottom-color: #FFFFFF;
  border-bottom-width: 3px !important;
}

.has-background-grey-dark {
  background-color: #555555 !important;
}

li ul {
  border-left: 2px solid #ffffff !important;
}

.highlightedChar {
  color: #f9e300;
}
</style>
<template>
  <span>
    &nbsp;
    <b-icon v-if="isWarning" type="is-warning" pack="fas" icon="exclamation-triangle" :size="iconSize"></b-icon>
  </span>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "WarningLanguageIcon",
  props: {
  	size: String,
  },
  computed: {
  	isWarning() {
  		return i18n.t("language_warning_flag")==="true";
  	},
  	iconSize() {
  		let value;
  		switch(this.size) {
  			case "small": value="is-size-8"; break;
  			case "medium": value="is-size-7"; break;
  			case "large": value="is-size-5"; break;
  			default: break;
  		}
  		return value;
  	}
  }
};
</script>

<style scoped>
	.icon {
	  height:0.8rem;
	}
</style>
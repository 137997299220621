<template>
  <div id="header">
    <b-navbar :mobile-burger="isShowBurgerMenu" shadow fixed-top centered>
      <template slot="brand">
        <HeaderLogo :isMaximized="!isMinimized" :isDesktop="isDesktop" :isTablet="isTablet" :isMobile="isMobile" :isMobileSmall="isMobileSmall" />
        <HeaderTabletNavigationBar :isVisible="!isMinimized" />
        <HeaderLanguageMenu :isInMaximizedNavbar="!isMinimized" />
      </template>
      <template slot="start">
        <HeaderBurgerMenu :hidden="!isShowBurgerMenu" :isMobile="isMobile" />
      </template>
    </b-navbar>
  </div>
</template>
<script>
import HeaderLogo from "./HeaderLogo.vue";
import HeaderLanguageMenu from "./HeaderLanguageMenu.vue";
import HeaderTabletNavigationBar from "./HeaderTabletNavigationBar.vue";
import HeaderBurgerMenu from "./HeaderBurgerMenu.vue";

export default {
  name: "Header",
  data: function() {
    return {
      body: Object,
      navbar: Object,
      navbarBrand: Object
    };
  },
  props: {
    isMinimized: { type: Boolean, default: false },
    isMobileSmall: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    isTablet: { type: Boolean, default: false },
    isDesktop: { type: Boolean, default: false }
  },
  components: {
    HeaderLogo,
    HeaderLanguageMenu,
    HeaderTabletNavigationBar,
    HeaderBurgerMenu
  },
  mounted: function() {
    /* Save the body, navbar and brand nodes when the site is loaded.
       Both are used to minimize and maximize the Header when the user scrolls.
       Sadly we can't use a more elegant solution as Buefy creates those nodes when compiling.
    */
    this.body = document.getElementsByClassName("has-navbar-fixed-top")[0];
    this.navbar = document.getElementsByClassName("navbar")[0];
    this.navbarBrand = document.getElementsByClassName("navbar-brand")[0];

    this.maximize();
  },
  methods: {
    // Method to minimize the Header when the user scrolls down
    minimize: function() {
      if (this.body && this.navbar && this.navbarBrand) {
        this.body.classList.remove("maximizedBody");
        this.navbar.classList.remove("maximizedNavbar");
        this.body.classList.add("minimizedBody");
        this.navbar.classList.add("minimizedNavbar");
        this.navbarBrand.classList.add("minimizedNavbarBrand");
      }
    },
    // Method to maximize the Header when the user scrolls to the up
    maximize: function() {
      if (this.body && this.navbar && this.navbarBrand) {
        this.body.classList.remove("minimizedBody");
        this.navbar.classList.remove("minimizedNavbar");
        this.navbarBrand.classList.remove("minimizedNavbarBrand");
        this.body.classList.add("maximizedBody");
        this.navbar.classList.add("maximizedNavbar");
      }
    }
  },
  computed: {
    isShowBurgerMenu: function() {
      return this.isMobileSmall || this.isMobile || (this.isTablet && this.isMinimized);
    }
  },
  watch: {
    isMinimized: function() {
      if (this.isMinimized) {
        this.minimize();
      } else {
        this.maximize();
      }
    }
  }
};
</script>
<style>
/* Overriding the default burger menu to be placed on the left-hand side */
.navbar-burger {
  height: 100% !important;
  width: 5rem !important;
  position: absolute !important;
}

/* The following selectors minimize or maximize the Navbar.
   See the style section in App.vue for the default (maximized) Navbar height.
*/
.minimizedBody {
  padding-top: 5rem !important;
  transition: padding-top 0.75s;
}

.maximizedBody {
  padding-top: 10rem !important;
  transition: padding-top 0.75s;
}

.minimizedNavbar {
  min-height: 5rem !important;
  max-height: 5rem !important;
  transition: min-height 0.75s, max-height 0.75s;
}

.maximizedNavbar {
  min-height: 10rem !important;
  max-height: 10rem !important;
  transition: min-height 0.75s, max-height 0.75s;
}

.minimizedNavbarBrand {
  min-height: 5rem !important;
}
</style>
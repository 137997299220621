import links from "@/assets/data/links.json";
import mails from "@/assets/data/mails.json";
import i18n from "@/i18n";

function getContributorsList() {
  let result = "";

  if (links && links.contributors && links.contributors.length > 0) {
    links.contributors.forEach(c => {
      result += c + ", ";
    });

    result = result.slice(0, -2); // remove last two characters (", ")
  }

  return result;
}

function simpleName(name) {
  return name.replace("CARD TRIP", "CT");
}

function displayDecimals(number) {
  return parseFloat(number).toFixed(2);
}

function formatMailAddress(type) {
  return mails[type].id + "@" + mails[type].dom + "." + mails[type].end;
}

function formatOrderMailSubject(gameName, selectedQuantity, selectedCountry) {
  return i18n.t('order_mail_subject') + " " + gameName + " x" + selectedQuantity + " - " + selectedCountry.name.toUpperCase();
}

function formatOrderMailContent(gameName, selectedCountry, priceTotalFinal) {
  return i18n.t('order_mail_intro') + "\n\n" +
  i18n.t('order_mail_resume') + " " + gameName + ".\n\n" +
  i18n.t('order_mail_address') + "\n" +
  "<" + i18n.t('order_mail_firstname') + "> <" + i18n.t('order_mail_lastname') + ">\n" +
  "<" + i18n.t('order_mail_addressLine1') + "> <" + i18n.t('order_mail_addressLine2') + ">\n" +
  "<" + i18n.t('order_mail_postalcode') + "> <" + i18n.t('order_mail_city') + ">\n" +
  selectedCountry.name.toUpperCase() + "\n\n" +
  "▬▬▬▬▬" + "\n\n" +
  i18n.t('order_mail_info[0]') + "\n\n" +
  i18n.t('order_mail_info[1]') + " " + priceTotalFinal + " € " + i18n.t('order_mail_info[2]') + "\n" +
  i18n.t('order_mail_info[3]') + "\n\n" +
  i18n.t('order_mail_info[4]') + "\n\n" +
  i18n.t('order_mail_info[5]') + "\n\n"
}

function mergeArrays(arrayOfarrays, key) {
  var r = [],
  hash = Object.create(null);

  arrayOfarrays.forEach(function(a) {
    a.forEach(function(o) {
      if (!hash[o[key]]) {
        hash[o[key]] = {};
        r.push(hash[o[key]]);
      }
      Object.keys(o).forEach(function(k) {
        hash[o[key]][k] = o[k];
      });
    });
  });
  return r;
}

export {
  getContributorsList,
  simpleName,
  displayDecimals,
  formatMailAddress,
  formatOrderMailSubject,
  formatOrderMailContent,
  mergeArrays
};
<template>
  <div id="languageMenu" class="navbar-item has-dropdown is-hoverable" :class="{inMaximizedNavbar:isInMaximizedNavbar, inMinimizedNavbar:!isInMaximizedNavbar}">
    <b-dropdown v-model="$i18n.locale" position="is-bottom-left" aria-role="list" @change="onLanguageChanged">
      <b-button size="is-medium" type="is-info" slot="trigger" slot-scope="{ active }">
        <span>{{ $i18n.locale }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'" class="centerIcon"></b-icon>
      </b-button>
      <b-dropdown-item aria-role="listitem" v-for="entry in entries" :key="entry.key" :name="entry.key" :value="entry.key">{{ entry.key }} - {{ entry.name }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { getInactiveLocales } from "@/helpers/localeHelper.js";
import i18n from "@/i18n";

export default {
  name: "HeaderLanguageMenu",
  props: {
    isInMaximizedNavbar: { type: Boolean }
  },
  computed: {
    entries: function() {
      return getInactiveLocales(i18n.locale);
    }
  },
  methods: {
    onLanguageChanged: function() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }
};
</script>
<style scoped>
#languageMenu {
  position: absolute !important;
  right: 0;
  display: flex;
  margin-right: 4%;
}

.inMinimizedNavbar {
  margin-top: 17.5px;
  transition: margin-top 0.75s;
}

.inMaximizedNavbar {
  margin-top: 57.5px;
  transition: margin-top 0.75s;
}
</style>
<template>
  <router-link id="logo" to="/" :class="logoClasses">
    <img alt="Card Trip" src="@/assets/img/logo/logo_rgb.png" class="image" :class="imgClasses" @click="scrollToTop" />
  </router-link>
</template>
<script>
export default {
  name: "HeaderLogo",
  props: {
    isMaximized: Boolean,
    isDesktop: Boolean,
    isTablet: Boolean,
    isMobile: Boolean,
    isMobileSmall: Boolean
  },
  computed: {
    logoClasses: function() {
      return {
        maximizedSmallLogo: this.isMaximized && this.isMobile
      };
    },
    imgClasses: function() {
      return {
        minImage: !this.isMaximized && !this.isMobileSmall,
        mobileSmallMinImage: !this.isMaximized && this.isMobileSmall,
        desktopMaxImage: this.isMaximized,
        tabletMaxImage: this.isMaximized && this.isTablet,
        mobileMaxImage: this.isMaximized && this.isMobile,
        mobileSmallMaxImage: this.isMaximized && this.isMobileSmall
      };
    }
  },
  methods: {
    scrollToTop(){
      if(window.scrollY != 0) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }
  }
};
</script>
<style scoped>
#logo {
  top: 1rem;
  position: fixed;
  left: 50%;
  margin: auto auto 0 -40%;
  width: 80%;
  transition: top 0.75s;
}

#logo:focus {
  outline: none;
}

.maximizedSmallLogo {
  top: 2.5rem !important;
  transition: top 0.75s;
}

.image {
  margin: auto;
}

.mobileSmallMinImage {
  margin-top: 0.25rem;
  max-height: 2rem;
  transition: margin-top 0.75s;
  /*transition: max-height 0.75s;*/
}

.minImage {
  margin-top: 0.25rem;
  max-height: 3rem;
  transition: max-height 0.75s;
}

.desktopMaxImage {
  max-height: 8rem;
  transition: max-height 0.75s;
}

.tabletMaxImage {
  max-height: 6rem;
  /* The medium logo needs to be smaller to not obscur the navigation button */
  transition: max-height 0.75s;
}

.mobileMaxImage {
  max-height: 4rem;
}

.mobileSmallMaxImage {
  margin-top: 1.5rem;
  max-height: 2rem;
  transition: margin-top 0.75s;
  /*transition: max-height 0.75s;*/
}

.router-link-exact-active {
  background-color: transparent !important;
}
</style>
<template>
  <div>
    <section id="introduction" class="section">
      <h1 class="title is-1">{{ $t("games_title") }}</h1>
      <SectionText fieldName="games_intro"></SectionText>
    </section>
    <section id="brussels" class="section">
      <SectionTitle fieldName="games_ctbru_description_title"></SectionTitle>
      <GameDescription productID="ctbru" photoFilePath="photos/product profile/ct_bru-profile.png" :presentation="$t('games_ctbru_description_presentation')" :characteristics="$t('games_ctbru_description_characteristics')" :references="$t('games_ctbru_description_references')" />
      <section id="moreGameImages" class="section">
        <div class="columns is-desktop">
          <div class="column"><ImagePopout filePath="photos/compositions/ct_bru-composition1.png" /></div>
          <div class="column"><ImagePopout filePath="photos/product anatomy/ct_bru-anatomy.jpg" /></div>
          <div class="column"><ImagePopout filePath="photos/product anatomy/ct_bru-cards.png" /></div>
        </div>
      </section>
      <div id="copyrights">
        <SectionTitle fieldName="games_ctbru_copyrights_title" isSubtitle></SectionTitle>
        <div class="columns is-multiline is-desktop">
          <div class="column is-half-desktop">
            <h5 class="subtitle is-5 alignLeft">{{ $t("games_ctbru_copyrights_collections_title") }}</h5>
            <ul>
              <li v-for="cr of $t('games_ctbru_copyrights_collections_entries')" :key="cr" class="small">{{ cr }}</li>
            </ul>
          </div>
          <div class="column is-half-desktop">
            <h5 class="subtitle is-5 alignLeft">{{ $t("games_ctbru_copyrights_activities_title") }}</h5>
            <ul>
              <li v-for="cr of $t('games_ctbru_copyrights_activities_entries')" :key="cr" class="small">{{ cr }}</li>
            </ul>
          </div>
          <div class="column is-half-desktop">
            <h5 class="subtitle is-5 alignLeft">{{ $t("games_ctbru_copyrights_relaxations_title") }}</h5>
            <ul>
              <li v-for="cr of $t('games_ctbru_copyrights_relaxations_entries')" :key="cr" class="small">{{ cr }}</li>
            </ul>
          </div>
          <div class="column is-half-desktop">
            <h3 class="subtitle is-5 alignLeft">{{ $t("games_ctbru_copyrights_discoveries_title") }}</h3>
            <ul>
              <li v-for="cr of $t('games_ctbru_copyrights_discoveries_entries')" :key="cr" class="small">{{ cr }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <SectionTitle fieldName="games_ctbru_contributors_title" isSubtitle></SectionTitle>
        <SectionText fieldName="games_ctbru_contributors_content"></SectionText>
        <br>
        <p class="small">{{ contributorsList }}</p>
      </div>
      <br>
    </section>
    <br />
  </div>
</template>
<script>
import ImagePopout from "@/components/ImagePopout.vue";
import GameDescription from "@/components/GameDescription.vue";
import { getContributorsList } from "@/helpers/dataHelper.js";
import SectionTitle from "@/components/SectionTitle.vue";
import SectionText from "@/components/SectionText.vue";

export default {
  name: "Games",
  components: {
    ImagePopout,
    GameDescription,
    SectionTitle,
    SectionText
  },
  computed: {
    contributorsList: function() {
      return getContributorsList();
    }
  }
};
</script>
<style scoped>
.boxImage {
  max-width: 400px !important;
}

.small {
  font-size: small;
}
</style>
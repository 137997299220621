<template>
  <div>
    <div class="field has-addons">
      <b-select size="is-medium" icon="globe" icon-pack="fas" v-model="selectedLocale">
        <option v-for="code in languageCodes" :key="code" :value="code">{{ code }}</option>
      </b-select>
      <b-button size="is-medium" type="is-info" class="sharpCornersLeft">
        <a class="has-text-white-ter semibold" :href="getDownloadLink(localeToDownload)" target="_blank">{{ $t("download_text") }}</a>
      </b-button>
    </div>
  </div>
</template>
<script>
import i18n from "@/i18n";
import gamerules from "@/assets/data/gamerules.json";

const DefaultLocale = "EN";

export default {
  name: "DownloadRules",
  data: function() {
    return { selectedLocale: String };
  },
  props: {
    gameRulesID: String // See assets/data/gamerules.json for all IDs
  },
  mounted: function() {
    this.onLocaleChangedGlobally();
  },
  methods: {
    getDownloadLink: function(locale) {
      return "/rulebooks/" + gamerules[this.gameRulesID][locale];
    },
    rulesExist: function(locale) {
      return gamerules[this.gameRulesID][locale];
    },
    onLocaleChangedGlobally: function() {
      if (this.rulesExist(i18n.locale)) {
        this.selectedLocale = i18n.locale;
      } else {
        this.selectedLocale = DefaultLocale;
      }
    }
  },
  computed: {
    languageCodes: function() {
      return Object.keys(gamerules[this.gameRulesID]);
    },
    localeToDownload: function() {
      if (this.rulesExist(this.selectedLocale)) {
        return this.selectedLocale;
      }

      return DefaultLocale;
    }
  },
  watch: {
    "$i18n.locale": function() {
      this.onLocaleChangedGlobally();
    }
  }
};
</script>
<style scoped>
.field {
  padding-top: 0.4rem;
  padding-bottom: 0.5rem;
}

.sharpCornersLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<template>
  <footer class="footer has-background-grey-dark">
    <div class="content has-text-centered">
      © {{ yearRange() }} Myriatex. {{ $t('footer_rights') }}
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  methods:{
    yearRange() {
      const startYear = 2019,
      currentYear = new Date().getFullYear();
      if (currentYear === startYear) {
        return startYear;
      } else {
        return startYear + "-" + currentYear;
      }
    }
  }
};
</script>
<style scoped>
footer {
  position: fixed;

  width: 100%;
  text-align: center;
  bottom: 0;
  padding: 0.75rem 1.5rem 0.75rem;
  z-index: 5;
}
</style>
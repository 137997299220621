import links from "@/assets/data/links.json";

function isHTML(text) {
  if (text.startsWith("¤") || text.startsWith("~") || text.endsWith("¤¤¤") || text.endsWith("¤¤¤¤") || text.endsWith("¤¤¤¤¤")) {
    return true;
  }

  return false;
}

function getHTML(text) {
  //Titles
  if (text.startsWith("¤¤¤¤¤")) {
    text = text.substring(5, text.length);
    text = "<h5 class='subtitle is-6 alignLeft'>" + text + "</h5>";
    return text;
  }

  if (text.startsWith("¤¤¤¤")) {
    text = text.substring(4, text.length);
    text = "<h5 class='subtitle is-5 alignLeft'>" + text + "</h5>";
    return text;
  }

  if (text.startsWith("¤¤¤")) {
    text = text.substring(3, text.length);
    text = "<h5 class='subtitle is-5 alignLeft headerLinesRight'>" + text + "</h5>";
    return text;
  }

  if (text.startsWith("¤¤")) {
    text = text.substring(2, text.length);
    text = "<h5 class='subtitle is-5 alignLeft'>" + text + "</h5>";
    return text;
  }

  if (text.startsWith("¤")) {
    text = text.substring(1, text.length);
    text = "<h3 class='subtitle is-3 alignLeft'>" + text + "</h3>";
    return text;
  }

  //Paragraphs
  if (text.startsWith("~")) {
    text = text.substring(1, text.length);
    // Non-indexed External Links - [text](url)
    text = text.replace(/\[(.+)\]\((https?:\/\/[^ ]+)\)/g, "<a class=\"textLink\" target=\"_blank\" href=\"$2\">$1</a>");
    // Indexed External Links - [text](url_index)
    text = text.replace(/\[(.+)\]\(([^ ]+)\)/g, function(x, name, urlIndex) {
      return "<a class=\"textLink\" target=\"_blank\" href=\"" + links[urlIndex] + "\">" + name + "</a>";
    });

    // Local Links - [text](link relative path)
    // text = text.replace(/\[([^[]]+)\]\((\/[^ ]+)\)/g,"<a class=\"textLink\" href=\"$2\">$1</a>");


    // Automatic replacement of missing external links
    text = text.replace(/([^"])(https?:\/\/[^ ]+[A-z/])([^"]?$)/g, "$1<a class=\"textLink\" target=\"_blank\" href=\"$2\">$2$3</a>");

    // Colored Text - {text}(color)
    text = text.replace(/\{([^()]+)\}\(([^() ]+)\)/g, "<span style=\"color:$2\">$1</span>");

    // **bold text**
    text = text.replace(/\*\*([^*]+)\*\*/g, "<span class=\"bold\">$1</span>");

    //Remarks
    if (text.startsWith("~")) {
      text = text.substring(1, text.length);
      text = "<div style=\"line-height:0.5\">&nbsp;</div><div class=\"remark\">" + text + "</div>";
    }
  }

  if (text.endsWith("¤¤¤¤")) {
    text = text.substring(0, text.length - 4)
    return "<p>" + text + "</p>" + "<div style=\"line-height:0.5\">&nbsp;</div>";
  } else if (text.endsWith("¤¤¤")) {
    text = text.substring(0, text.length - 3)
    return "<p>" + text + "</p>" + "<div style=\"line-height:18px\">&nbsp;</div>";
  } else {
    return "<p>" + text + "</p>";
  }
}

function isPicture(text) {
  return text.startsWith("!");
}

function getPicture(text) {
  //Image - ![text](image relative path)
  let result = new RegExp(/!picture\((.+)\)/).exec(text);
  return require('@/assets/img/' + result[1]);
}

export { isHTML, getHTML, isPicture, getPicture };
<template>
  <div>
    <section id="introduction" class="section">
      <h1 class="title is-1">{{ $t("about_title") }}</h1>
      <SectionText fieldName="about_intro"></SectionText>
    </section>
    <section v-if="clickedMember" id="team" class="section">
      <SectionTitle fieldName="about_team_title"></SectionTitle>
      <div class="columns">
        <div class="column" v-for="member of $t('about_team_members')" :key="member.name">
          <div :class="{'has-background-grey':clickedMember.name === member.name}" class="card is-outlined" v-on:click="onMemberClicked(member)">
            <div class="card-image is-flex is-horizontal-center">
              <figure class="image is-96x96">
                <img class="is-rounded memberMargin" :src="require('@/assets/img/roles/'+  member.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')  +'.svg')" />
              </figure>
            </div>
            <div class="card-content">
              <h4 class="title is-4 is-hidden-touch">{{ member.name }}</h4>
              <h6 class="title is-6 is-hidden-mobile is-hidden-desktop">{{ member.name }}</h6>
              <h4 class="title is-4 is-hidden-tablet">{{ member.name }}</h4>
              <h6 class="subtitle is-6">{{ member.title }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="box has-background-grey-dark">
        <p>{{ clickedMember.interests }}</p>
        <br />
        <p>{{ clickedMember.role }}</p>
        <br />
        <span>
          <strong class="catchphrase">{{ clickedMember.catchphrase }}</strong>
          <b-button class="is-info is-pulled-right" @click="onWriteMessageClicked()">{{ $t("about_team_writeMessage") }}</b-button>
        </span>
      </div>
    </section>
    <section id="project" class="section">
      <SectionTitle fieldName="about_project_title"></SectionTitle>
      <SectionText fieldName="about_project_intro"></SectionText>
      <SectionText fieldName="about_project_details"></SectionText>
      <ImagePopout filePath="photos/prototype/prototypes_overview.jpg" :text="$t('about_project_pictureLegend')" :reduceWidth="true" />
      <SectionText fieldName="about_project_futur"></SectionText>
    </section>
    <section id="contact" class="section">
      <SectionTitle fieldName="about_contact_title"></SectionTitle>
      <div class="box has-background-grey-dark">
        <div class="columns is-multiline">
          <div class="column">
            <b-field :label="$t('about_contact_to')" custom-class="alignLeft subtitle is-4">
              <b-select v-model="recipient" icon="account" required>
                <option value="Team">{{ $t("about_contact_team") }}</option>
                <option v-for="member of $t('about_team_members')" :key="member.name" :value="member.name">{{ member.name }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field :label="$t('about_contact_subject')" custom-class="alignLeft subtitle is-4">
              <b-select v-model="subject" icon="message" required>
                <option v-for="subject of $t('about_contact_subjects')" :key="subject" :value="subject">{{ subject }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="container is-clearfix">
          <b-input maxlength="2000" rows="8" type="textarea" :placeholder="$t('about_contact_messagePlaceholder')" v-model="message">{{ message }}</b-input>
          <b-button class="sendButton is-info is-pulled-right" @click="sendMail()">{{ $t("about_contact_send") }}</b-button>
        </div>
      </div>
    </section>
    <br />
  </div>
</template>
<script>
import i18n from "@/i18n";
import mails from "@/assets/data/mails.json";
import ImagePopout from "@/components/ImagePopout.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import SectionText from "@/components/SectionText.vue";

export default {
  name: "About",
  components: {
    ImagePopout,
    SectionTitle,
    SectionText
  },
  data() {
    return {
      // The member name is used as an ID. We can't store the member object directly, because references change when changing languages.
      clickedMemberName: String,

      // Contact form
      recipient: "",
      subject: "",
      message: ""
    };
  },
  methods: {
    onMemberClicked(member) {
      this.clickedMemberName = member.name;
    },
    onWriteMessageClicked() {
      this.recipient = this.clickedMemberName;
      location.hash = "#contact"; // always jumps to the contact form even if last router entry was /about#contact // this.$router.push({ name: "Contact" });
    },
    sendMail() {
      let addr = mails.contact.id + "@" + mails.contact.dom + "." + mails.contact.end;
      let subj = "@" + this.recipient + ": " + this.subject;
      let msg = this.message;

      window.location.href =
        "mailto:" + addr + "?subject=" + subj + "&body=" + encodeURI(msg);
    }
  },
  computed: {
    // Get the clicked member by using his name as an ID to search through all members.
    // The clicked member is not necessarily the recipient!
    clickedMember: function() {
      return i18n
        .t("about_team_members")
        .find(o => o.name === this.clickedMemberName);
    }
  },
  mounted() {
    // Select the first member and show his details
    this.onMemberClicked(i18n.t("about_team_members")[0]);

    // Select "Team" as the default recipient
    this.recipient = "Team";

    // Select the first subject in the contact form
    this.subject = i18n.t("about_contact_subjects")[0];
  }
};
</script>
<style>
.is-horizontal-center {
  /* used to center the card image horizontally */
  justify-content: center;
}

.memberMargin {
  /* used to give a small upper margin to the card image */
  margin-top: 20px;
}

.label {
  color: hsl(0, 0%, 96%);
}

.box {
  max-width: 880px;
  margin-left: auto;
  margin-right: auto;
}

.card {
  height: 100%;
}

.card:hover {
  cursor: pointer;
}

.catchphrase {
  line-height: 2;
}

.field-body {
  /* helps to keep the contact form inputs apart */
  margin-right: 3rem;
}

.textarea {
  /* Don't allow message field to be resizable */
  resize: none;
}

#contact {
  margin-bottom: 1rem;
}
</style>
<template>
  <div>
    <br>
    <div id="cardContainer" class="is-hidden-desktop">
      <div>
        <figure class="image">
          <img id="baseImage" :src="imgSrc" @click="isShowModal=true">
        </figure>
      </div>
      <div v-if="text" class="card-content">
        <div class="content">{{ text }}</div>
      </div>
    </div>
    <div id="cardContainer" class="is-hidden-touch" :class="{reduced: reduceWidth}">
      <div>
        <figure class="image">
          <img id="baseImage" :src="imgSrc" @click="isShowModal=true">
        </figure>
      </div>
      <div v-if="text" class="card-content">
        <div class="content">{{ text }}</div>
      </div>
    </div>
    <b-modal :active.sync="isShowModal">
      <img :src="imgSrc" />
    </b-modal>
    <br>
  </div>
</template>
<script>
export default {
  name: "ImagePopout",
  data: function() {
    return {
      isShowModal: false
    };
  },
  props: {
    filePath: String,
    text: String,
    reduceWidth: { type: Boolean, default: false }
  },
  computed: {
    imgSrc: function() {
      return require('@/assets/img/' + this.filePath);
    }
  }
};
</script>
<style scoped>
#baseImage {
  cursor: pointer;
  /*border: 1px solid black;*/
}

#baseImage:hover {
  opacity: 0.7;
}

.reduced {
  max-width: 65%;
}

#cardContainer {
  /*  border: 3px solid green;*/
  margin-left: auto;
  margin-right: auto;
}
</style>
<template>
  <div id="tabletNavigationBar" class="is-hidden-mobile is-hidden-desktop" :class="{visible:isVisible, hidden:!isVisible}">
    <b-dropdown aria-role="menu">
      <b-button class="has-background-grey-dark has-text-white-ter" slot="trigger" slot-scope="{ active }">
        <span>{{ $t("nav_home") }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'" class="centerIcon"></b-icon>
      </b-button>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/">{{ $t("nav_home") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/play">{{ $t("nav_home_play") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/explore">{{ $t("nav_home_explore") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/share">{{ $t("nav_home_share") }}</router-link>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown aria-role="menu">
      <b-button class="has-background-grey-dark has-text-white-ter" slot="trigger" slot-scope="{ active }">
        <span>{{ $t("nav_games") }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'" class="centerIcon"></b-icon>
      </b-button>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/games">{{ $t("nav_games") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/brussels">{{ $t("nav_games_brussels") }}</router-link>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown aria-role="menu">
      <b-button class="has-background-grey-dark has-text-white-ter" slot="trigger" slot-scope="{ active }">
        <span>{{ $t("nav_about") }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'" class="centerIcon"></b-icon>
      </b-button>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/about">{{ $t("nav_about") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/team">{{ $t("nav_about_team") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/project">{{ $t("nav_about_project") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/contact">{{ $t("nav_about_contact") }}</router-link>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown aria-role="menu">
      <b-button class="has-background-grey-dark has-text-white-ter" slot="trigger" slot-scope="{ active }">
        <span>{{ $t("nav_legal") }}</span>
        <b-icon :icon="active ? 'menu-up' : 'menu-down'" class="centerIcon"></b-icon>
      </b-button>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/legal">{{ $t("nav_legal") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/information">{{ $t("nav_legal_information") }}</router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/terms">{{ $t("nav_legal_terms") }}
          <WarningLanguageIcon size="small" />
        </router-link>
      </b-dropdown-item>
      <b-dropdown-item has-link aria-role="menuitem">
        <router-link to="/privacy">{{ $t("nav_legal_privacy") }}
          <WarningLanguageIcon size="small" />
        </router-link>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import WarningLanguageIcon from "@/components/warning/WarningLanguageIcon.vue";

export default {
  name: "HeaderTabletNavigationBar",
  props: {
    isVisible: Boolean
  },
  components: {
    WarningLanguageIcon
  }
};
</script>
<style scoped>
#tabletNavigationBar {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: 5px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s;
  /* set visibility to hidden after 0.5s with a 0s (=no) transition */
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s;
  transition-delay: 0.6s;
}

.button {
  border-color: transparent;
}
</style>
<template>
  <div>
    <div class="columns has-padding-left-right is-hidden-touch">
      <br />
      <div v-if="!isImgRight" class="column image image-desktop">
        <div v-if="isPopout">
          <ImagePopout :filePath="filePath" />
        </div>
        <div v-else >
          <img :src="imgSrc" />
        </div>
      </div>
      <div class="column" :class="{hasPaddingLeft:!isImgRight,hasPaddingRight:isImgRight}">
        <h3 v-if="title" class="subtitle is-3 alignLeft">{{ title }}</h3>
        <div v-for="section of text" :key="section">
          <div v-if="isHTML(section)" v-html="getHTML(section)" />
          <p v-else>{{ section }}</p>
        </div>
        <br v-if="text && list" />
        <ul v-if="list">
          <li v-for="x of list" :key="x">
            <div v-if="isHTML(x)" v-html="getHTML(x)" />
            <p v-else>{{ x }}</p>
          </li>
        </ul>
      </div>
      <div v-if="isImgRight" class="column image image-desktop">
        <div v-if="isPopout">
          <ImagePopout :filePath="filePath" />
        </div>
        <div v-else >
          <img :src="imgSrc" />
        </div>
      </div>
    </div>
    <div class="is-hidden-desktop">
      <div v-if="!isImgRight" class="image image-touch">
        <div v-if="isPopout">
          <ImagePopout :filePath="filePath" />
        </div>
        <div v-else >
          <img :src="imgSrc" />
        </div>
        <br>
      </div>
      <div>
        <h3 v-if="title" class="subtitle is-3 alignLeft">{{ title }}</h3>
        <div v-for="section of text" :key="section">
          <div v-if="isHTML(section)" v-html="getHTML(section)" />
          <p v-else>{{ section }}</p>
        </div>
        <br v-if="text && list" />
        <ul v-if="list">
          <li v-for="x of list" :key="x">
            <div v-if="isHTML(x)" v-html="getHTML(x)" />
            <p v-else>{{ x }}</p>
          </li>
        </ul>
      </div>
      <div v-if="isImgRight" class="image image-touch">
        <br>
        <div v-if="isPopout">
          <ImagePopout :filePath="filePath" />
        </div>
        <div v-else >
          <img :src="imgSrc" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isHTML, getHTML } from "@/helpers/parser.js";
import ImagePopout from "@/components/ImagePopout.vue";

export default {
  name: "ImageAndText",
  components: {
    ImagePopout
  },
  methods: {
    isHTML,
    getHTML
  },
  props: {
    filePath: String,
    title: String,
    text: Array,
    list: Array,
    isImgRight: { type: Boolean, default: false },
    isPopout: { type: Boolean, default: false }
  },
  computed: {
    imgSrc: function() {
      return require("@/assets/img/" + this.filePath);
    }
  }
};
</script>
<style scoped>
.image {
  max-width: 400px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-desktop {
  margin-top: auto;
  margin-bottom: auto;
}

.image-touch {
  margin-bottom: 24px;
}

.has-padding-left-right {
  padding-left: 10px;
  padding-right: 10px;
}

.hasPaddingLeft {
  padding: 0 0 0 40px;
}

.hasPaddingRight {
  padding: 0 40px 0 0;
}

.columns {
  margin-top: 0;
}
</style>
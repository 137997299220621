<template>
  <div>
    <section id="mentions" class="section">
      <h1 class="title is-1">{{ $t("legal_title") }}</h1>
      <SectionText fieldName="legal_intro"></SectionText>
    </section>
    <section id="information" class="section">
      <SectionTitle fieldName="legal_legalInfo_title"></SectionTitle>
      <SectionText fieldName="legal_legalInfo_content"></SectionText>
    </section>
    <section id="terms" class="section">
      <SectionTitle fieldName="legal_terms_title" isWarning></SectionTitle>
      <WarningLanguageNotification />
      <SectionText fieldName="legal_terms_intro"></SectionText>
      <Tabs isUnique :title="$t('legal_terms_general_title')" :tabArray="$t('legal_terms_general_content')" />
      <Tabs isUnique :title="$t('legal_terms_use_title')" :tabArray="$t('legal_terms_use_content')" />
      <Tabs isUnique :title="$t('legal_terms_sales_title')" :tabArray="$t('legal_terms_sales_content')" />
      <Tabs isUnique :title="$t('legal_terms_pricing_title')" :tabArray="$t('legal_terms_pricing_content')" />
    </section>
    <section id="privacy" class="section">
      <SectionTitle fieldName="legal_privacyPolicy_title" isWarning></SectionTitle>
      <WarningLanguageNotification />
      <SectionText fieldName="legal_privacyPolicy_intro"></SectionText>
      <Tabs isUnique :title="$t('legal_privacyPolicy_collection_title')" :tabArray="$t('legal_privacyPolicy_collection_content')" />
      <Tabs isUnique :title="$t('legal_privacyPolicy_use_title')" :tabArray="$t('legal_privacyPolicy_use_content')" />
      <Tabs isUnique :title="$t('legal_privacyPolicy_time_title')" :tabArray="$t('legal_privacyPolicy_time_content')" />
      <Tabs isUnique :title="$t('legal_privacyPolicy_access_title')" :tabArray="$t('legal_privacyPolicy_access_content')" />
      <Tabs isUnique :title="$t('legal_privacyPolicy_transfer_title')" :tabArray="$t('legal_privacyPolicy_transfer_content')" />
      <Tabs isUnique :title="$t('legal_privacyPolicy_protection_title')" :tabArray="$t('legal_privacyPolicy_protection_content')" />
      <Tabs isUnique :title="$t('legal_privacyPolicy_rights_title')" :tabArray="$t('legal_privacyPolicy_rights_content')" />
    </section>
    <br />
  </div>
</template>
<script>
import Tabs from "@/components/Tabs.vue";
import WarningLanguageNotification from "@/components/warning/WarningLanguageNotification.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import SectionText from "@/components/SectionText.vue";


export default {
  name: "Legal",
  components: {
    Tabs,
    WarningLanguageNotification,
    SectionTitle,
    SectionText
  }
};
</script>